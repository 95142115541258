import axios from "axios";

const serverUrl = "https://webapi.startupp.cloud/access/WebAPI/";
const devServerUrl = "https://localhost:44302/access/WebAPI/";
const isDev = false;
const isSandbox = false;

const gargleRequestKey = "4c590b60-5163-4d7a-9ed8-88f0d73a71b2";
const dentalMarketingRequestKey = "4d3d57e1-1d9b-4f4b-9f64-16574f211c27";

const authorize = async (context, isGargle) => {
  return new Promise((resolve, reject) => {
    const sParams = {
      requestKey: isGargle ? gargleRequestKey : dentalMarketingRequestKey,
      action: "authorize",
    };

    try {
      if (!context.AUTH_CODE) {
        axios
          .post(isDev ? devServerUrl : serverUrl, sParams)
          .then((response) => {
            if (response) {
              context.AUTH_CODE = response.data.authorization;
              resolve(context.AUTH_CODE);
            }
          })
          .catch((error) => {
            context.AUTH_CODE = "";
            reject(context.AUTH_CODE);
          });
      } else {
        resolve(context.AUTH_CODE);
      }
    } catch (err) {
      context.AUTH_CODE = "";
      reject(context.AUTH_CODE);
    }
  });
};

export const query = async (obj, fields, filter, context, isGargle) => {
  try {
    const AUTH_CODE = await authorize(context, isGargle); // Requests an authorization code before making actual calls

    const sParams = {
      requestKey: isGargle ? gargleRequestKey : dentalMarketingRequestKey,
      requestCode: AUTH_CODE,
      action: "sf-search",
      search: [
        {
          sobject: obj,
          fields: fields,
          filter: filter,
        },
      ],
    };

    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );

    const response = data;
    return response[obj];
  } catch (err) {
    return null;
  }
};
