import React, { useContext, useState } from "react";
import { UserContext } from "../../App";
import { query } from "../API";
import { Row } from "react-bootstrap";
import Loader from "../Loader";

const ClientList = () => {
  const [data, setData] = useState(null);

  const context = useContext(UserContext);

  context.gargleRequestKey = "4c590b60-5163-4d7a-9ed8-88f0d73a71b2";
  const columns = ["Account", "Last Received Date"];

  if (!data) {
    query(
      "Account",
      "Id, Name, Widget_Installation_Status__c, Last_Widget_Data_Received_Date__c, Enable_Widget_Sync__c",
      "Widget_Installation_Status__c != null AND (NOT Name LIKE '%test%')",
      context,
      true
    ).then((gargleData) => {
      setData(gargleData);
    });
  }

  if (data) {
    setTimeout(() => {
      window["buildDataTable"](
        [
          { width: "80%", targets: 0 },
          { width: "30%", targets: 1 },
        ],
        [1, "desc"]
      );
    }, 500);
  }

  return (
    <>
      {
        <div className="content" style={{ margin: "0", padding: "40px" }}>
          {!data && <Loader />}
          {data && (
            <>
              <Row>
                <div
                  className="chart-container"
                  style={{ width: "800px", margin: "0 auto" }}
                >
                  <div className="cs-title">
                    Syncing (
                    {
                      data.filter(
                        (a) =>
                          a.Widget_Installation_Status__c ===
                          "Installed, Syncing"
                      ).length
                    }
                    )
                  </div>
                  <table
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="main-table display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter(
                          (a) =>
                            a.Widget_Installation_Status__c ===
                            "Installed, Syncing"
                        )
                        .map((a) => {
                          return (
                            <tr key={"row-" + a.Name}>
                              <td>{a.Name}</td>
                              <td>{a.Last_Widget_Data_Received_Date__c}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <div className="cs-title">
                    Outdated, Syncing (
                    {
                      data.filter(
                        (a) =>
                          a.Widget_Installation_Status__c ===
                          "Outdated, Syncing"
                      ).length
                    }
                    )
                  </div>
                  <table
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="main-table display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter(
                          (a) =>
                            a.Widget_Installation_Status__c ===
                            "Outdated, Syncing"
                        )
                        .map((a) => {
                          return (
                            <tr key={"row-" + a.Name}>
                              <td>{a.Name}</td>
                              <td>{a.Last_Widget_Data_Received_Date__c}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <div className="cs-title">
                    Broken (
                    {
                      data.filter(
                        (a) => a.Widget_Installation_Status__c === "Broken"
                      ).length
                    }
                    )
                  </div>
                  <table
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="main-table display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter(
                          (a) => a.Widget_Installation_Status__c === "Broken"
                        )
                        .map((a) => {
                          return (
                            <tr key={"row-" + a.Name}>
                              <td>{a.Name}</td>
                              <td>{a.Last_Widget_Data_Received_Date__c}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <div className="cs-title">
                    Contacted, Not Installed (
                    {
                      data.filter(
                        (a) => a.Widget_Installation_Status__c === "Contacted"
                      ).length
                    }
                    )
                  </div>
                  <table
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="main-table display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter(
                          (a) => a.Widget_Installation_Status__c === "Contacted"
                        )
                        .map((a) => {
                          return (
                            <tr key={"row-" + a.Name}>
                              <td>{a.Name}</td>
                              <td>{a.Last_Widget_Data_Received_Date__c}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <div className="cs-title">
                    Others (
                    {
                      data.filter(
                        (a) =>
                          a.Widget_Installation_Status__c !==
                            "Installed, Syncing" &&
                          a.Widget_Installation_Status__c !==
                            "Outdated, Syncing" &&
                          a.Widget_Installation_Status__c !== "Contacted" &&
                          a.Widget_Installation_Status__c !== "Broken"
                      ).length
                    }
                    )
                  </div>
                  <table
                    style={{
                      display: "none",
                      minWidth: "100%",
                      width: "100%",
                    }}
                    border="0"
                    className="main-table display stripe row-border order-column"
                  >
                    <thead>
                      <tr>
                        {columns.map((c) => {
                          return <th key={"col-" + c}>{c}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter(
                          (a) =>
                            a.Widget_Installation_Status__c !==
                              "Installed, Syncing" &&
                            a.Widget_Installation_Status__c !==
                              "Outdated, Syncing" &&
                            a.Widget_Installation_Status__c !== "Contacted" &&
                            a.Widget_Installation_Status__c !== "Broken"
                        )
                        .map((a) => {
                          return (
                            <tr key={"row-" + a.Name}>
                              <td>{a.Name}</td>
                              <td>{a.Last_Widget_Data_Received_Date__c}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Row>
            </>
          )}
        </div>
      }
    </>
  );
};

export default ClientList;
