import Img from "../../loader.gif";

const Loader = () => {
  return (
    <div className="loading">
      <h2>Loading data</h2>
      <h5>This can take a couple of seconds...</h5>
      <br />
      <div className="loader">
        <img src={Img} alt="Loading info..." />
      </div>
    </div>
  );
};

export default Loader;
